import React from 'react';
import { graphql } from 'gatsby';
import Hero from 'sections/hero';
import PageTitleColumns from 'sections/page-title-columns';
import RenderBlocks, { BlockType } from 'utils/RenderBlocks';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import LangSlug from 'components/lang-slug';

type PageTemplateProps = {
  data: GatsbyTypes.PageTemplateQuery;
};

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  if (!data.page) return null;

  const { heroImage, topLeftColumn, topRightColumn, body, langSlugs } = data.page;

  return (
    <>
      <HelmetDatoCms seo={data.page.seoMetaTags} />
      <LangSlug langList={langSlugs as GatsbyTypes.LangSlugFragment[]} model={'page'} />
      <Hero image={heroImage} />
      <PageTitleColumns
        leftColumn={<div dangerouslySetInnerHTML={{ __html: topLeftColumn as string }} />}
        rightColumn={<div dangerouslySetInnerHTML={{ __html: topRightColumn as string }} />}
      />
      <RenderBlocks data={body as BlockType[]} />
    </>
  );
};

export default PageTemplate;

export const query = graphql`
  query PageTemplate($id: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: datoCmsPage(id: { eq: $id }) {
      heroImage {
        ...Image
      }
      langSlugs {
        ...LangSlug
      }
      seoMetaTags {
        tags
      }
      topLeftColumn
      topRightColumn
      body {
        ...Spacing
        ...ServiceSection
        ...LargeLinkCta
        ...GraphicHeader
        ...ListSection
        ...NewsSelection
        ...Accordion
        ...Gallery
        ...ImageAndTextCta
        ...PostList
        ...LogotypesSection
      }
    }
  }
`;
