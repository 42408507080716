import clsx from 'clsx';
import React from 'react';
import Arrow from 'components/arrow';
import Column from 'components/column';
import Section from 'components/section';

import * as styles from './page-title-columns.module.scss';

type PageTitleColumnsProps = {
  leftColumn: React.ReactNode;
  rightColumn: React.ReactNode;
  hideArrow?: boolean;
};

const PageTitleColumns: React.FC<PageTitleColumnsProps> = ({
  leftColumn,
  rightColumn,
  hideArrow,
}) => {
  return (
    <Section className={styles.section}>
      <Column sm={8}>
        <Column className={styles.column} xl={6} lg={6} md={6}>
          {leftColumn}
          {!hideArrow && (
            <Column className={styles.arrowDesktop} xl={5} lg={5} md={5}>
              <Arrow dir={'bottomRight'} />
            </Column>
          )}
        </Column>
        <Column
          className={styles.column}
          xl={{ width: 5, offset: 1 }}
          lg={{ width: 5, offset: 1 }}
          md={{ width: 5, offset: 1 }}
        >
          {rightColumn}
        </Column>
      </Column>
      {!hideArrow && (
        <Column className={clsx(styles.column, styles.arrowMobile)} sm={4} xs={4}>
          <Arrow dir={'bottomRight'} />
        </Column>
      )}
    </Section>
  );
};

export default PageTitleColumns;
